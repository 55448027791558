import React from 'react';
import { Link } from 'react-router-dom';
import { FaCalendarAlt } from 'react-icons/fa';
import './TourPackagesComponent.css';

const tourPackages = [
  {
    image: '/images/tour1.jpg',
    days: '1 day · Exclusive',
    name: 'Meet the gorillas upclose',
    itinerary: 'Transportation, Gorilla Trekking, Scenic views of Volcanoes National Park',
    price: '$210'
  },
  {
    image: '/images/tour2.jpg',
    days: '1 day · Cultural',
    name: 'Get a feel of how the kings lived',
    itinerary: 'Transportation, Cultural Exhibits, Historical Insights',
    price: '$130'
  },
  {
    image: '/images/tour3.jpg',
    days: '1 day · Exclusive',
    name: 'Meet the farmers',
    itinerary: 'transport, Soft drinks, tour guide',
    price: '$100'
  },
  {
    image: '/images/tour4.png',
    days: '1 day · Exclusive',
    name: 'Visit the Musanze caves',
    itinerary: 'Transportation, Guided tour of Musanze Caves, return to kigali',
    price: '$210'
  },
  {
    image: '/images/tour5.jpg',
    days: '1 day · Exclusive',
    name: 'Canopy Walk ',
    itinerary: 'Transportation,  Waterfall Hikes, Chimpanzee Tracking',
    price: '$550'
  },
];

const TourPackagesComponent = () => {
  return (
    <div className="tour-packages-section">
        <div className="spacing-container">
          <div className="line-wrapper">
            <p>Discover our tour packages.</p>
            <div className="elegant-line"></div>
          </div>
        </div>
    <div className="tour-packages-container">
      {tourPackages.length === 0 ? (
        <p>No Packages yet</p>
      ) : (
        <>
          <div className="grid-container">
          {tourPackages.map((pkg, index) => (
              <div key={index} className="tour-package">
                <img src={pkg.image} alt={pkg.name} className="tour-image" />
                <div className="tour-info">
                  <div className="days"> <FaCalendarAlt style={{ marginRight: '5px' }} />{pkg.days}</div>
                  <h3>{pkg.name}</h3>
                  <p>{pkg.itinerary}</p>
                </div>
                <div className="price-and-button">
                <div className="price">
                    <span className="from">from </span>
                    <span className="amount">{pkg.price}</span>
                  </div>
                  <button className="book-now-btn1">Book Trip</button>
                </div>
              </div>
            ))}

            <div className="more-info">
              <div className="description">
                Experience Rwanda's wonders with your personal 24/7 helper
              </div>
              <button className="view-btn"><Link to="/places" >See More Places</Link></button>
              <button className="view-btn"> <Link to="/package-tours" >See More Trips</Link></button>
            </div>
          </div>
          
          
          
        </>
      )}
    </div>
    </div>
  );
};

export default TourPackagesComponent;
