import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';
import './Breadcrumb.css';

const Breadcrumb = ({ paths, isFormPage }) => {
  const location = useLocation();
  

  return (
    <nav aria-label="breadcrumb" className={`breadcrumb-nav ${isFormPage ? 'form-breadcrumb' : ''}`}>
      <ol className="breadcrumb-list">
        {paths.map((crumb, index) => {
          const isLast = index === paths.length - 1;
          const isActive = location.pathname === crumb.link;
          return (
            <li key={index} className={`breadcrumb-item ${isLast ? 'breadcrumb-current' : ''}`}>
              <Link to={crumb.link} className={`breadcrumb-link ${isActive ? 'breadcrumb-link-active' : ''}`}>
                {crumb.icon && <span className="breadcrumb-icon">{crumb.icon}</span>}
                {crumb.name}
              </Link>
              {!isLast && <FaChevronRight className="breadcrumb-separator" />}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
