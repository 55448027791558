import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import NavBar from '../Components/NavBar';
import { SyncLoader } from 'react-spinners';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './MyBooking.css';
import Breadcrumb from '../Components/Breadcrumb';

const MyBooking = () => {
  const navigate = useNavigate();
  
  const initialValues = {
    name: '',
    email: '',
    phoneNumber: '',
    tourPackageID: '',
    tourPackageName: '',
    message: ''
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    tourPackageID: Yup.string(),
    tourPackageName: Yup.string(),
    message: Yup.string().required('Message is required')
  }).test('package-check', null, (obj) => {
    if (!obj.tourPackageID && !obj.tourPackageName) {
      return new Yup.ValidationError(
        'Either Tour Package ID or Tour Package Name must be provided',
        null,
        'tourPackageID'
      );
    }
    return true;
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await axios.post('https://mo-tours-backend.onrender.com/my-booking', values);
      console.log(response.data);
      toast.success('Thank you for your inquiry! We will contact you soon.');
      resetForm();
    } catch (error) {
      toast.error('Submission Failed: There was an error processing your request. Please try again later.');
      // console.error(error.response ? error.response.data : error.message);
    }
    setSubmitting(false);
  };

  const paths = [
    { name: 'Home', link: '/' },
    { name: 'My booking', link: '/my-booking' }
  ];

  return (
    <>
      <NavBar showFullNav={false} />
      <Breadcrumb paths={paths} isFormPage={true} />
      <div className="my-booking-container">
      <button className="back-button" onClick={() => navigate(-1)}><FaArrowLeft /></button>
        <h2>My Booking</h2>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <div className="form-group">
                <label>Name</label>
                <Field type="text" name="name" />
                <ErrorMessage name="name" component="div" className="error" />
              </div>
              <div className="form-group">
                <label>Email</label>
                <Field type="email" name="email" />
                <ErrorMessage name="email" component="div" className="error" />
              </div>
              <div className="form-group">
                <label>Phone Number</label>
                <Field type="text" name="phoneNumber" />
                <ErrorMessage name="phoneNumber" component="div" className="error" />
              </div>
              <div className="form-group">
                <label>Tour Package ID</label>
                <Field type="text" name="tourPackageID" />
                <ErrorMessage name="tourPackageID" component="div" className="error" />
              </div>
              <div className="form-group">
                <label>Tour Package Name</label>
                <Field type="text" name="tourPackageName" />
                <ErrorMessage name="tourPackageName" component="div" className="error" />
              </div>
              <div className="form-group">
                <label>Message</label>
                <Field as="textarea" name="message" />
                <ErrorMessage name="message" component="div" className="error" />
              </div>
              <button className="my-booking-btn" type="submit" disabled={isSubmitting}>{isSubmitting ? <SyncLoader size={8} color="#ffffff" /> : 'Submit'}</button>
            </Form>
          )}
        </Formik>
        <ToastContainer />
      </div>
    </>
  );
};

export default MyBooking;
