import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './AdminDashboard.css';
import NavBar from '../Components/NavBar';
import { Container, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Drawer, List, ListItem, ListItemText, Menu, MenuItem, Typography, Grid, Card, CardContent, CardActions} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon, Add as AddIcon, PersonAdd as PersonAddIcon, Settings } from '@mui/icons-material';
import { FaMapMarkerAlt, FaUserAlt, FaStar} from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';


const AdminDashboard = ({ authToken, userRole }) => {
  const [places, setPlaces] = useState([]);
  const [tourPackages, setTourPackages] = useState([]);
  const [customTours, setCustomTours] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [requestQuote, setRequestQuotes]= useState([]);
  const [reviews, setReviews] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState('places');
  const [showForm, setShowForm] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAdminForm, setShowAdminForm] = useState(false);

  const fetchData = useCallback((entity) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };

    const entityMap = {
      places: () => axios.get('https://mo-tours-backend.onrender.com/places', config).then(response => setPlaces(response.data)),
      'package-tours': () => axios.get('https://mo-tours-backend.onrender.com/package-tours', config).then(response => setTourPackages(response.data)),
      'custom-tours': () => axios.get('https://mo-tours-backend.onrender.com/custom-tours', config).then(response => setCustomTours(response.data)),
      'book-now': () => axios.get('https://mo-tours-backend.onrender.com/book-now', config).then(response => setBookings(response.data)),
      'contact-us': () => axios.get('https://mo-tours-backend.onrender.com/contact-us', config).then(response => setContacts(response.data)),
      'request-quote': () => axios.get('https://mo-tours-backend.onrender.com/request-quote', config).then(response => setRequestQuotes(response.data)),
      reviews: () => axios.get('https://mo-tours-backend.onrender.com/reviews', config).then(response => setReviews(response.data)),
    };

    entityMap[entity]().catch(error => console.error(error));
  }, [authToken]);

  useEffect(() => {
    fetchData(selectedEntity);
  }, [selectedEntity, fetchData]);

  const handleDelete = () => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };

    axios.delete(`https://mo-tours-backend.onrender.com/${itemToDelete.entity}/${itemToDelete.item._id}`, config)
      .then(() => {
        setShowDeleteConfirmation(false);
        fetchData(selectedEntity);
        toast.success('Item deleted successfully!');
      })
      .catch(error => console.error(error));
  };

  const handleEdit = (entity, item) => {
    setCurrentItem({ entity, item });
    setShowForm(true);
  };

  const handleCreate = (entity) => {
    setSelectedEntity(entity);
    setCurrentItem(null);
    setShowForm(true);
    handleCloseMenu();
  };

    const handleSubmit = (values, { setSubmitting }) => {
      const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };

    const entity = currentItem ? currentItem.entity : selectedEntity;
    const url = currentItem ? `https://mo-tours-backend.onrender.com/${entity}/${currentItem.item._id}` : `http://localhost:5000/${entity}`;
    const method = currentItem ? 'put' : 'post';

    axios({ url, method, data: values, ...config })
    .then(() => {
      setShowForm(false);
      fetchData(entity);
      toast.success(`Item ${currentItem ? 'updated' : 'created'} successfully!`);
    })
    .catch(error => console.error(error))
    .finally(() => setSubmitting(false));
  };

  const handleCreateAdmin = (values, { setSubmitting }) => {
  
    const data = {
      name: values.name,
      email: values.email,
      password: values.password,
      userRole: values.userRole
    };
  
    axios.post('https://mo-tours-backend.onrender.com/admin/register', data)
      .then((response) => {
        setShowAdminForm(false);
        toast.success('Admin user created successfully!');
      })
      .catch(error => {
        // console.error('Error occurred:', error.response?.data || error.message);
        toast.error('Error creating admin user.');
      })
      .finally(() => setSubmitting(false));
  };
  

  const renderEntity = () => {
    const entityMap = {
      places: places.map(item => (
        <Grid item xs={12} sm={6} md={4} key={item._id}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
               <FaMapMarkerAlt style={{ color: '#044fa0', fontSize: '14px' }} /> {item.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.description}
              </Typography>
            </CardContent>
            <CardActions>
            {['editor', 'admin', 'superadmin'].includes(userRole) && (
                <Button onClick={() => handleEdit('places', item)} startIcon={<EditIcon />} size="small">
                  Edit
                </Button>
              )}
              <Button onClick={() => handleDeleteConfirmation('places', item)} startIcon={<DeleteIcon />} size="small">
                Delete
              </Button>
            </CardActions>
          </Card>
        </Grid>
      )),
      'package-tours': tourPackages.map(item => (
        <Grid item xs={12} sm={6} md={4} key={item._id}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
              <FaMapMarkerAlt style={{ color: '#044fa0', fontSize: '14px' }} />{item.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.description}
              </Typography>
            </CardContent>
            <CardActions>
            {['editor', 'admin', 'superadmin'].includes(userRole) && (
                <Button onClick={() => handleEdit('package-tours', item)} startIcon={<EditIcon />} size="small">
                  Edit
                </Button>
              )}
              <Button onClick={() => handleDeleteConfirmation('package-tours', item)} startIcon={<DeleteIcon />} size="small">
                Delete
              </Button>
            </CardActions>
          </Card>
        </Grid>
      )),
      'custom-tours': customTours.map(item => (
        <Grid item xs={12} sm={6} md={4} key={item._id}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
              <FaUserAlt style={{ color: '#044fa0', fontSize: '14px' }} />{item.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.message}
              </Typography>
            </CardContent>
            <CardActions>
            {['editor', 'admin', 'superadmin'].includes(userRole) && (
                <Button onClick={() => handleEdit('custom-tours', item)} startIcon={<EditIcon />} size="small">
                  Edit
                </Button>
              )}
              <Button onClick={() => handleDeleteConfirmation('custom-tours', item)} startIcon={<DeleteIcon />} size="small">
                Delete
              </Button>
            </CardActions>
          </Card>
        </Grid>
      )),
      'book-now': bookings.map(item => (
        <Grid item xs={12} sm={6} md={4} key={item._id}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
              <FaUserAlt style={{ color: '#044fa0', fontSize: '14px' }} />{item.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.departureDate}
              </Typography>
            </CardContent>
            <CardActions>
            {['editor', 'admin', 'superadmin'].includes(userRole) && (
                <Button onClick={() => handleEdit('book-now', item)} startIcon={<EditIcon />} size="small">
                  Edit
                </Button>
              )}
              <Button onClick={() => handleDeleteConfirmation('book-now', item)} startIcon={<DeleteIcon />} size="small">
                Delete
              </Button>
            </CardActions>
          </Card>
        </Grid>
      )),
      'contact-us': contacts.map(item => (
        <Grid item xs={12} sm={6} md={4} key={item._id}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
              <FaUserAlt style={{ color: '#044fa0', fontSize: '14px' }} />{item.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.message}
              </Typography>
            </CardContent>
            <CardActions>
            {['editor', 'admin', 'superadmin'].includes(userRole) && (
                <Button onClick={() => handleEdit('contact-us', item)} startIcon={<EditIcon />} size="small">
                  Edit
                </Button>
              )}
              <Button onClick={() => handleDeleteConfirmation('contact-us', item)} startIcon={<DeleteIcon />} size="small">
                Delete
              </Button>
            </CardActions>
          </Card>
        </Grid>
      )),
      'request-quote': requestQuote.map(item => (
        <Grid item xs={12} sm={6} md={4} key={item._id}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
              <FaUserAlt style={{ color: '#044fa0', fontSize: '14px' }} />{item.email}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.departureDate}<br />
                {item.packageTour.name}
              </Typography>
            </CardContent>
            <CardActions>
            {['editor', 'admin', 'superadmin'].includes(userRole) && (
                <Button onClick={() => handleEdit('request-quote', item)} startIcon={<EditIcon />} size="small">
                  Edit
                </Button>
              )}
              <Button onClick={() => handleDeleteConfirmation('request-quote', item)} startIcon={<DeleteIcon />} size="small">
                Delete
              </Button>
            </CardActions>
          </Card>
        </Grid>
      )),
      reviews: reviews.map(item => (
        <Grid item xs={12} sm={6} md={4} key={item._id}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
              <FaUserAlt style={{ color: '#044fa0', fontSize: '14px' }} />{item.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Rating: {item.rating} <FaStar color="gold" />
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.comment}
              </Typography>
            </CardContent>
            <CardActions>
            {['editor', 'admin', 'superadmin'].includes(userRole) && (
                <Button onClick={() => handleEdit('reviews', item)} startIcon={<EditIcon />} size="small">
                  Edit
                </Button>
              )}
              <Button onClick={() => handleDeleteConfirmation('reviews', item)} startIcon={<DeleteIcon />} size="small">
                Delete
              </Button>
            </CardActions>
          </Card>
        </Grid>
      )),
    };

    return (
      <Grid container spacing={3}>
        {entityMap[selectedEntity]}
      </Grid>
    );
  };

  const handleDeleteConfirmation = (entity, item) => {
    setItemToDelete({ entity, item });
    setShowDeleteConfirmation(true);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const validationSchema = Yup.lazy((values) => {
    switch (selectedEntity) {
      case 'places':
        return Yup.object().shape({
          name: Yup.string().required('Name is required'),
          description: Yup.string().required('Description is required'),
          image: Yup.string().url('Invalid URL').required('Image URL is required'),
        });
  
      case 'package-tours':
        return Yup.object().shape({
          name: Yup.string().required('Name is required'),
          description: Yup.string().required('Description is required'),
          price: Yup.number().required('Price is required'),
          image: Yup.string().url('Invalid URL').required('Image URL is required'),
          itinerary: Yup.string().required('Itinerary is required'),
          inclusions: Yup.string().required('Inclusions are required'),
          exclusions: Yup.string().required('Exclusions are required'),
        });
  
      case 'custom-tours':
        return Yup.object().shape({
          name: Yup.string().required('Name is required'),
          email: Yup.string().email('Invalid email').required('Email is required'),
          phoneNumber: Yup.string(),
          departureDate: Yup.date().required('Departure date is required'),
          duration: Yup.number().required('Duration is required'),
          numberOfTravellers: Yup.number().required('Number of travellers is required'),
          message: Yup.string().required('Message is required'),
        });
  
      case 'reviews':
        return Yup.object().shape({
          name: Yup.string().required('Name is required'),
          rating: Yup.number().required('Rating is required').min(1).max(5),
          comment: Yup.string().required('Comment is required'),
          date: Yup.date().required('Date is required'),
        });
  
      default:
        return Yup.object().shape({});
    }
  });
  


  return (
    <>
      <NavBar showFullNav={false} />
      <Drawer
        variant="permanent"
        anchor="left"
      >
        <Typography variant="h6" color="#044fa0" noWrap>
          Menu
        </Typography>
        <List>
          <ListItem button onClick={() => setSelectedEntity('places')}>
            <ListItemText primary="Manage Places" />
            <Settings style={{ marginLeft: '8px', fontSize: '16px' }} />
          </ListItem>
          <ListItem button onClick={() => setSelectedEntity('package-tours')}>
            <ListItemText primary="Manage Tour Packages" />
            <Settings style={{ marginLeft: '8px', fontSize: '16px' }} />
          </ListItem>
          <ListItem button onClick={() => setSelectedEntity('custom-tours')}>
            <ListItemText primary="Manage Custom Tours" />
            <Settings style={{ marginLeft: '8px', fontSize: '16px' }} />
          </ListItem>
          <ListItem button onClick={() => setSelectedEntity('book-now')}>
            <ListItemText primary="Manage Bookings" />
            <Settings style={{ marginLeft: '8px', fontSize: '16px' }} />
          </ListItem>
          <ListItem button onClick={() => setSelectedEntity('contact-us')}>
            <ListItemText primary="Manage Contacts" />
            <Settings style={{ marginLeft: '8px', fontSize: '16px' }} />
          </ListItem>
          <ListItem button onClick={() => setSelectedEntity('request-quote')}>
            <ListItemText primary="Manage Request quotes" />
            <Settings style={{ marginLeft: '8px', fontSize: '16px' }} />
          </ListItem>
          <ListItem button onClick={() => setSelectedEntity('reviews')}>
            <ListItemText primary="Manage Reviews" />
            <Settings style={{ marginLeft: '8px', fontSize: '16px' }} />
          </ListItem>
          {userRole !== 'editor' && (
            <ListItem button onClick={handleMenuClick}>
              <ListItemText primary="Create New" />
              <AddIcon style={{ marginRight: '8px', fontSize: '16px' }} />
            </ListItem>
          )}
          {userRole === 'superadmin' && (
            <ListItem button onClick={() => setShowAdminForm(true)}>
              <ListItemText primary="Create New Admin User" />
              <PersonAddIcon style={{ marginLeft: '8px', fontSize: '16px' }} />
            </ListItem>
          )}
        </List>
      </Drawer>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => handleCreate('places')}><AddIcon style={{ marginRight: '8px' }} />Place</MenuItem>
        <MenuItem onClick={() => handleCreate('package-tours')}><AddIcon style={{ marginRight: '8px' }} />Tour Package</MenuItem>
        <MenuItem onClick={() => handleCreate('reviews')}><AddIcon style={{ marginRight: '8px' }} />Review</MenuItem>
        <MenuItem onClick={() => handleCreate('custom-tours')}><AddIcon style={{ marginRight: '8px' }} />Custom Tours</MenuItem>
      </Menu>
      <Container>
        <Box className="admin-dashboard">
          <h1>Admin Dashboard</h1>
          <ul className="admin-list">
            {renderEntity()}
          </ul>
        </Box>
      </Container>

      <Dialog open={showForm} onClose={() => setShowForm(false)}>
        <DialogTitle>{currentItem ? 'Edit' : 'Create New'} {selectedEntity}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              name: currentItem?.item?.name || '',
              description: currentItem?.item?.description || '',
              price: currentItem?.item?.price || '',
              image: currentItem?.item?.image || '',
              itinerary: currentItem?.item?.itinerary?.join(',') || '',
              inclusions: currentItem?.item?.inclusions?.join(',') || '',
              exclusions: currentItem?.item?.exclusions?.join(',') || '',
              email: currentItem?.item?.email || '',
              phoneNumber: currentItem?.item?.phoneNumber || '',
              departureDate: currentItem?.item?.departureDate ? currentItem?.item?.departureDate.split('T')[0] : '',
              duration: currentItem?.item?.duration || '',
              numberOfTravellers: currentItem?.item?.numberOfTravellers || '',
              message: currentItem?.item?.message || '',
              rating: currentItem?.item?.rating || '',
              comment: currentItem?.item?.comment || '',
              date: currentItem?.item?.date?.split('T')[0] || '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
              <Field as={TextField} name="name" label="Name" fullWidth margin="normal" required />
              <ErrorMessage name="name" component="div" className="error" />
              
              {selectedEntity === 'places' && (
                <>
                  <Field as={TextField} name="description" label="Description" fullWidth margin="normal" required />
                  <ErrorMessage name="description" component="div" className="error" />
                  <Field as={TextField} name="image" label="Image URL" fullWidth margin="normal" required />
                  <ErrorMessage name="image" component="div" className="error" />
                </>
              )}
              
              {selectedEntity === 'package-tours' && (
                <>
                  <Field as={TextField} name="description" label="Description" fullWidth margin="normal" required />
                  <ErrorMessage name="description" component="div" className="error" />
                  <Field as={TextField} name="price" label="Price" fullWidth margin="normal" required />
                  <ErrorMessage name="price" component="div" className="error" />
                  <Field as={TextField} name="image" label="Image URL" fullWidth margin="normal" required />
                  <ErrorMessage name="image" component="div" className="error" />
                  <Field as={TextField} name="itinerary" label="Itinerary (comma-separated)" fullWidth margin="normal" required />
                  <ErrorMessage name="itinerary" component="div" className="error" />
                  <Field as={TextField} name="inclusions" label="Inclusions (comma-separated)" fullWidth margin="normal" required />
                  <ErrorMessage name="inclusions" component="div" className="error" />
                  <Field as={TextField} name="exclusions" label="Exclusions (comma-separated)" fullWidth margin="normal" required />
                  <ErrorMessage name="exclusions" component="div" className="error" />
                </>
              )}
              
              {selectedEntity === 'custom-tours' && (
                <>
                  <Field as={TextField} name="email" label="Email" fullWidth margin="normal" required />
                  <ErrorMessage name="email" component="div" className="error" />
                  <Field as={TextField} name="phoneNumber" label="Phone Number" fullWidth margin="normal" />
                  <ErrorMessage name="phoneNumber" component="div" className="error" />
                  <Field as={TextField} name="departureDate"  type="date" fullWidth margin="normal" required />
                  <ErrorMessage name="departureDate" component="div" className="error" />
                  <Field as={TextField} name="duration" label="Duration (days)" fullWidth margin="normal" required />
                  <ErrorMessage name="duration" component="div" className="error" />
                  <Field as={TextField} name="numberOfTravellers" label="Number of Travellers" fullWidth margin="normal" required />
                  <ErrorMessage name="numberOfTravellers" component="div" className="error" />
                  <Field as={TextField} name="message" label="Message" fullWidth margin="normal" required />
                  <ErrorMessage name="message" component="div" className="error" />
                </>
              )}
              
              {selectedEntity === 'reviews' && (
                <>
                  <Field as={TextField} name="rating" label="Rating" fullWidth margin="normal" required />
                  <ErrorMessage name="rating" component="div" className="error" />
                  <Field as={TextField} name="comment" label="Comment" fullWidth margin="normal" required />
                  <ErrorMessage name="comment" component="div" className="error" />
                  <Field as={TextField} name="date"  type="date" fullWidth margin="normal" required />
                  <ErrorMessage name="date" component="div" className="error" />
                </>
              )}
              
              <DialogActions>
                <Button onClick={() => setShowForm(false)}>Cancel</Button>
                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                  {currentItem ? 'Update' : 'Create'}
                </Button>
              </DialogActions>
            </Form>
            
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      <Dialog open={showAdminForm} onClose={() => setShowAdminForm(false)}>
        <DialogTitle>Create New Admin User</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              name: '',
              email: '',
              password: '',
              userRole: '',
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Name is required'),
              email: Yup.string().email('Invalid email').required('Email is required'),
              password: Yup.string().required('Password is required'),
              userRole: Yup.string().required('Role is required'),
            })}
            onSubmit={handleCreateAdmin}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field as={TextField} name="name" label="Name" fullWidth margin="normal" required />
                <ErrorMessage name="name" component="div" className="error" />
                <Field as={TextField} name="email" label="Email" fullWidth margin="normal" required />
                <ErrorMessage name="email" component="div" className="error" />
                <Field as={TextField} name="password" label="Password" type="password" fullWidth margin="normal" required />
                <ErrorMessage name="password" component="div" className="error" />
                <Field as={TextField} name="userRole" label="Role" fullWidth margin="normal" required />
                <ErrorMessage name="userRole" component="div" className="error" />
                <DialogActions>
                  <Button onClick={() => setShowAdminForm(false)}>Cancel</Button>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                    Create Admin
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      <Dialog open={showDeleteConfirmation} onClose={() => setShowDeleteConfirmation(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this item?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteConfirmation(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>

      <ToastContainer />
    </>
  );
};

export default AdminDashboard;

