import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Select from 'react-select';
import { SyncLoader } from 'react-spinners';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './LeaveReviewComponent.css';

const LeaveReviewComponent = () => {
  const initialValues = {
    name: '',
    rating: null,
    comment: ''
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    rating: Yup.object()
      .shape({
        value: Yup.number().required('Rating is required').min(1).max(5),
        label: Yup.string().required()
      })
      .nullable()
      .required('Rating is required'),
    comment: Yup.string().required('Comment is required')
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const reviewDetails = {
      name: values.name,
      rating: values.rating.value,
      comment: values.comment
    };

    try {
      await axios.post('https://mo-tours-backend.onrender.com/reviews', reviewDetails);
      toast.success('Thank you for your review! We appreciate your feedback.');
      resetForm();
    } catch (error) {
      toast.error('Submission Failed: There was an error submitting your review. Please try again later.');
    }
    setSubmitting(false);
  };

  const ratingOptions = [
    { value: 1, label: '1 Star' },
    { value: 2, label: '2 Stars' },
    { value: 3, label: '3 Stars' },
    { value: 4, label: '4 Stars' },
    { value: 5, label: '5 Stars' }
  ];

  return (
    <div className="leave-review-container">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, setFieldValue }) => (
          <Form className="review-form">
            <div className="form-group">
              <Field name="rating">
                {({ field }) => (
                  <>
                  <Select
                    {...field}
                    options={ratingOptions}
                    onChange={(option) => setFieldValue('rating', option)}
                    placeholder="Rating*"
                    value={field.value}
                  />
                  <ErrorMessage name="rating" component="div" className="error" />
                  </>
                )}
              </Field>
              
            </div>
            <div className="form-group">
              <Field type="text" name="name" placeholder="Name*" />
              <ErrorMessage name="name" component="div" className="error" />
            </div>
            <div className="form-group">
              <Field as="textarea" name="comment" placeholder="Comment" />
              <ErrorMessage name="comment" component="div" className="error" />
            </div>
            <button type="submit" className="review-submit-btn" disabled={isSubmitting}>{isSubmitting ? <SyncLoader size={8} color="#ffffff" /> : 'Submit Review'}</button>
          </Form>
        )}
      </Formik>
      <ToastContainer />
    </div>
  );
};

export default LeaveReviewComponent;
