import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ScrollPagesToTop from './ScrollPagesToTop';
import Homepage from './Pages/Homepage';
import Places from './Pages/Places';
import TourPackagesPage from './Pages/TourPackagesPage';
import TourPackageDetail from './Pages/TourPackageDetail';
import BookTour from './Pages/BookTour';
import CustomTour from './Pages/CustomTour';
import AdminLogin from './Pages/AdminLogin';
import AdminDashboard from './Pages/AdminDashboard';
import ContactUs from './Pages/ContactUs';
import Footer from './Components/Footer';
import LeaveReview from './Pages/LeaveReview';
import MyBooking from './Pages/MyBooking';
import FAQ from './Pages/FAQ';


const App = () => {
  const [authToken, setAuthToken] = useState(null);
  const [userRole, setUserRole] = useState(null);
 

 
  const PrivateRoute = ({ element, ...rest }) => {
    return authToken ? element : <Navigate to="/admin" />;
  };





  return (
    <Router>
      <ScrollPagesToTop>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/places" element={<Places />} />
        <Route path="/tour-packages" element={<TourPackagesPage />} />
        <Route path="/tour-packages/:id" element={<TourPackageDetail />} />
        <Route path="/book-now" element={<BookTour />} />
        <Route path="/book-now/:packageId" element={<BookTour />} />
        <Route path="/custom-tour" element={<CustomTour />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/leave-review" element={<LeaveReview />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/my-booking" element={<MyBooking />} />
        <Route path="/admin" element={<AdminLogin setAuthToken={setAuthToken} setUserRole={setUserRole} />} />
        <Route path="/admin/dashboard" element={<PrivateRoute element={<AdminDashboard authToken={authToken} userRole={userRole} />} />} />
        </Routes>
      </ScrollPagesToTop>
      <Footer />
    </Router>
  );
};

export default App;
