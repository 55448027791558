import React from 'react';
import './WhyChooseUsComponent.css';
import { FaGlobe, FaLock, FaHandsHelping, FaChartLine } from 'react-icons/fa'; // Importing icons from react-icons
import { Link } from 'react-router-dom';

const reasons = [
  {
    icon: <FaGlobe />,
    heading: 'Unparalleled Destinations',
    description: 'Explore Rwandas most captivating sites, from the bustling markets of Kigali to the tranquil waters of Lake Kivu, each tailored for an immersive and unforgettable experience.'
  },
  {
    icon: <FaLock />,
    heading: 'Your Safety, Our Priority',
    description: 'We prioritize your safety with secure payment methods, reliable transportation, and expertly guided tours for worry-free travel.'
  },
  {
    icon: <FaHandsHelping />,
    heading: 'Empowering Local Communities',
    description: 'Our tours are designed to enrich your experience while promoting sustainable tourism and supporting local communities, such as visits to the Kings Palace Museum and traditional markets.'
  },
  {
    icon: <FaChartLine />,
    heading: 'Excellence in Every Detail',
    description: 'From our professional tour guides to our commitment to customer satisfaction, we ensure top-tier service in every aspect—from seamless bookings to exceptional on-site experiences.'
  }
];

const WhyChooseUsComponent = () => {
  return (
    <div className="why-choose-us-section">
        <div className="spacing-container">
        <div className="line-wrapper">
          <p>Why Choose Us?</p>
          <div className="elegant-line"></div>
        </div>
      </div>
      <div className="why-choose-us-container">
        {reasons.map((reason, index) => (
          <div key={index} className="reason">
            <div className="reason-icon">{reason.icon}</div>
            <div className="reason-heading">{reason.heading}</div>
            <div className="reason-description">{reason.description}</div>
          </div>
        ))}
      </div>
      <div className="button-container">
      <Link to="/contact-us" className="contact-us-btn">Contact Us</Link>
      </div>
    </div>
  );
};

export default WhyChooseUsComponent;
