// src/Pages/FAQ.js
import React from 'react';
import Faq from 'react-faq-component';
import {useNavigate} from 'react-router-dom';
import { FaArrowLeft,FaPlus, FaMinus } from 'react-icons/fa';
import NavBar from '../Components/NavBar';
import './FAQ.css';
import Breadcrumb from '../Components/Breadcrumb';

const data = {
  title: "Frequently Asked Questions",
  rows: [
    {
      title: "What is the best time to visit Rwanda?",
      content: `The best time to visit Rwanda is during the dry seasons from June to September and December to February.`,
    },
    {
      title: "Do I need a visa to travel to Rwanda?",
      content: `Yes, most travelers need a visa to enter Rwanda. You can obtain a visa on arrival or apply for an e-visa online.`,
    },
    {
      title: "What should I pack for a safari in Rwanda?",
      content: `Pack light, breathable clothing, a hat, sunscreen, insect repellent, and comfortable walking shoes. Don't forget your camera and binoculars!`,
    },
    {
      title: "How can I book a tour with MO Tours?",
      content: `You can book a tour directly through our website by clicking the 'Book Now' button or by contacting us via phone or email.`,
    },
    {
      title: "What is included in the tour packages?",
      content: `Our tour packages typically include transportation, guided tours, entry fees to attractions, and some meals. Please check the specific tour details for more information.`,
    },
    {
      title: "Is it safe to travel in Rwanda?",
      content: `Yes, Rwanda is considered one of the safest countries in Africa for tourists. The country has low crime rates and a well-developed tourism infrastructure.`,
    },
    {
      title: "Can I customize my tour package?",
      content: `Absolutely! We offer customizable tour packages to suit your preferences and needs. Make a custom tour request by filling the form on Custom Tour or Contact us to discuss your specific requirements.`,
    },
    {
        title: "Are there any health precautions I should take before visiting Rwanda?",
        content: `It is recommended to get vaccinations for yellow fever, typhoid, hepatitis A and B, and to take malaria prophylaxis. Always check with your healthcare provider for the latest advice.`,
      },
      {
        title: "How can I get around Rwanda?",
        content: `You can get around Rwanda by public bus, private car hire, or using local moto-taxis (motorcycle taxis). Roads are generally in good condition.`,
      },
      {
        title: "Is tipping customary in Rwanda?",
        content: `Tipping is not mandatory in Rwanda but is appreciated. A 5-10% tip is generally acceptable in restaurants and for guides.`,
      },
      {
        title: "What electrical outlets are used in Rwanda?",
        content: `Rwanda uses Type C and J electrical outlets with a standard voltage of 230V. It is advisable to carry a universal adapter.`,
      },
      {
        title: "Can I drink tap water in Rwanda?",
        content: `It is advisable to drink bottled or filtered water in Rwanda to avoid any potential health issues.`,
      },
      {
        title: "What is the local cuisine like in Rwanda?",
        content: `Rwandan cuisine includes dishes like 'ugali' (aka fufu in west african countries), 'brochettes' (meat skewers), and 'aka benz' (very delicious roasted pork). Fresh fruit and vegetables are also abundant.`,
      },
      {
        title: "Can I go gorilla trekking in Rwanda?",
        content: `Yes, Rwanda is famous for gorilla trekking in Volcanoes National Park. It is a once-in-a-lifetime experience that requires a permit, which should and can be booked in advance on our website or by contacting us.`,
      },
      {
        title: "What should I do in case of an emergency while in Rwanda?",
        content: `In case of an emergency, dial 112 for police, 999 for fire, and 912 for medical assistance. It's also advisable to have your embassy's contact information handy.`,
      },
  ],
};

const styles = {
  bgColor: 'white',
  titleTextColor: "#044fa0",
  rowTitleColor: "#044fa0",
  rowContentColor: 'grey',
  arrowColor: "#044fa0",
};

const config = {
  animate: true,
  tabFocus: true,
  expandIcon: <FaPlus />,
  collapseIcon: <FaMinus />,
};

const paths = [
  { name: 'Home', link: '/' },
  { name: 'FAQ', link: '/faq' }
];

const FAQ = () => {
  const navigate = useNavigate();
  return (
    <>
      <NavBar showFullNav={false} />
      <Breadcrumb paths={paths} isFormPage={true} />
      <div className="faq-container">
      <button className="back-button" onClick={() => navigate(-1)}><FaArrowLeft /></button>
        <Faq data={data} styles={styles} config={config} />
      </div>
    </>
  );
};

export default FAQ;
