import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate} from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import NavBar from '../Components/NavBar';
import { SyncLoader } from 'react-spinners';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaArrowLeft } from 'react-icons/fa';
import './BookTour.css';
import Breadcrumb from '../Components/Breadcrumb';

const BookTour = () => {
  const location = useLocation();
  const [packages, setPackages] = useState([]);
  const [loadingPackages, setLoadingPackages] = useState(true);
  const [errorPackages, setErrorPackages] = useState(false);
  const navigate = useNavigate();


  const initialValues = {
    name: '',
    email: '',
    phoneNumber: '',
    departureDate: '',
    duration: '',
    numberOfTravellers: '',
    packageTour: location.state?.packageTour || '',
    message: ''
  };


  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phoneNumber: Yup.string().required('Phone Number is required'),
    departureDate: Yup.date().required('Departure Date is required'),
    duration: Yup.number().required('Duration is required'),
    numberOfTravellers: Yup.number().required('Number of Travellers is required'),
    packageTour: Yup.string().required('Package Tour is required'),
    message: Yup.string().required('Message is required')
  });



  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get('https://mo-tours-backend.onrender.com/package-tours');
        const packageOptions = response.data.map(pkg => ({
          value: pkg.name,
          label: pkg.name
        }));
        setPackages(packageOptions);
        setLoadingPackages(false);
      } catch (error) {
        toast.error('Error fetching tour packages:', error);
        setErrorPackages(true);
        setLoadingPackages(false);
      }
    };

    fetchPackages();
  }, []);


  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await axios.post('https://mo-tours-backend.onrender.com/book-now', values);
      toast.success('Thank you for booking your tour! We will contact you soon.');
      resetForm();
    } catch (err) {
      toast.error('Submission Failed: There was an error submitting your booking. Please try again later.');
      // console.error(err.response ? err.response.data : err.message);
    }
    setSubmitting(false);
  };

  const paths = [
    { name: 'Home', link: '/' },
    { name: 'Book Tour', link: '/book-now' }
  ];

  
  return (
    <>
      <NavBar showFullNav={false} />
      <Breadcrumb paths={paths} isFormPage={true} />
      <div className="book-tour-container">
      <button className="back-button" onClick={() => navigate(-1)}><FaArrowLeft /></button>
        <h2>Book Your Tour</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form className="book-tour-form">
              <div className="form-group">
                <label>Name</label>
                <Field type="text" name="name" />
                <ErrorMessage name="name" component="div" className="error" />
              </div>
              <div className="form-group">
                <label>Email</label>
                <Field type="email" name="email" />
                <ErrorMessage name="email" component="div" className="error" />
              </div>
              <div className="form-group">
                <label>Phone Number</label>
                <Field type="text" name="phoneNumber" />
                <ErrorMessage name="phoneNumber" component="div" className="error" />
              </div>
              <div className="form-group">
                <label>Departure Date</label>
                <Field type="date" name="departureDate" />
                <ErrorMessage name="departureDate" component="div" className="error" />
              </div>
              <div className="form-group">
                <label>Duration (days)</label>
                <Field type="number" name="duration" />
                <ErrorMessage name="duration" component="div" className="error" />
              </div>
              <div className="form-group">
                <label>Number of Travellers</label>
                <Field type="number" name="numberOfTravellers" />
                <ErrorMessage name="numberOfTravellers" component="div" className="error" />
              </div>
              <div className="form-group">
                <label>Package Tour</label>
                <Select
                  options={packages}
                  name="packageTour"
                  value={packages.find(option => option.value === values.packageTour) || null}
                  onChange={(option) => setFieldValue('packageTour', option.value)}
                  placeholder="Select a package"
                  noOptionsMessage={() => 
                    loadingPackages ? <SyncLoader size={8} color="#044fa0" /> : 
                    errorPackages ? "Failed to load packages" : 
                    "No packages available"
                  }
                />
                <ErrorMessage name="packageTour" component="div" className="error" />
              </div>
              <div className="form-group">
                <label>Message</label>
                <Field as="textarea" name="message" />
                <ErrorMessage name="message" component="div" className="error" />
              </div>
              <button className="book-tour-btn" type="submit" disabled={isSubmitting}>{isSubmitting ? <SyncLoader size={8} color="#ffffff" /> : 'Submit'}</button>
            </Form>
          )}
        </Formik>

        <ToastContainer />
      </div>
    </>
  );
};

export default BookTour;
