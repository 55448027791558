import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import HeroSection from '../Components/HeroSection'; // Adjust the import path as needed
import './TourPackageDetail.css';
import {FaAlignLeft, FaDollarSign, FaClock, FaRoute, FaMapMarkerAlt, FaCheckCircle, FaTimesCircle, FaHome, FaMapSigns, FaInfoCircle } from 'react-icons/fa';
import Breadcrumb from '../Components/Breadcrumb';
import PuffLoader from 'react-spinners/PuffLoader';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';


const TourPackageDetail = () => {
  const [tourPackage, setTourPackage] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState('details');

  useEffect(() => {
    const fetchTourPackage = async () => {
      try {
        const response = await axios.get(`https://mo-tours-backend.onrender.com/package-tours/${id}`);
        setTourPackage(response.data);
        setLoading(false);
      } catch (err) {
        toast.error('Error fetching places!');
        setLoading(false);
      }
    };

    fetchTourPackage();
  }, [id]);

  const paths = [
    { name: 'Home', link: '/', icon: <FaHome /> },
    { name: 'Tour Packages', link: '/tour-packages', icon: <FaMapSigns /> },
    { name: 'Tour Package Details', link: '/tour-packages/:id', icon: <FaInfoCircle /> }
  ];

  const itineraryItems = tourPackage?.itinerary || [];
  const inclusionsItems = tourPackage?.inclusions || [];
  const exclusionsItems = tourPackage?.exclusions || [];

  if (loading) {
    return (
      <div className="loader-container">
        <PuffLoader color="#044fa0" size={150} />
      </div>
    );
  }

  return (
    <>
      <ToastContainer />
      
      <HeroSection 
        title={tourPackage.name} 
        subtitle={tourPackage.description} 
        imageUrl="/images/cover2.jpg" 
        isHomepage={false} 
      />
       <Breadcrumb paths={paths} />
      <div className="tour-package-detail-container">
        <div className="tour-package-detail">
        <div className="tab-buttons">
            <button onClick={() => setActiveTab('details')} className={activeTab === 'details' ? 'active' : ''}><FaInfoCircle /> Details</button>
            <button onClick={() => setActiveTab('itinerary')} className={activeTab === 'itinerary' ? 'active' : ''}><FaRoute /> Itinerary</button>
            <button onClick={() => setActiveTab('inclusions')} className={activeTab === 'inclusions' ? 'active' : ''}><FaCheckCircle /> Inclusions</button>
            <button onClick={() => setActiveTab('exclusions')} className={activeTab === 'exclusions' ? 'active' : ''}><FaTimesCircle /> Exclusions</button>
          </div>
          <h2><FaMapMarkerAlt size={16} /> {tourPackage.name}</h2>
          <div className="tab-content">
            {activeTab === 'details' && (
              <div>
                <h2><FaInfoCircle  className="it-icons"/> Details</h2>
                <p><FaAlignLeft className="it-icons"/> {tourPackage.description}</p>
                <p><FaDollarSign className="it-icons"/> Price: ${tourPackage.price}</p>
                <p><FaClock className="it-icons"/> Duration: {tourPackage.duration}</p>
              </div>
            )}
            {activeTab === 'itinerary' && (
              <div>
                <h2><FaRoute className="it-icons"/> Itinerary</h2>
                {itineraryItems.length > 0 ? (
                  <ul className="itinerary-list">
                    {itineraryItems.map((item, index) => (
                      <li key={index}>
                        <FaCheckCircle style={{ color: '#044fa0' }} className="list-icons" /> {item}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No itinerary available.</p>
                )}
              </div>
            )}
            {activeTab === 'inclusions' && (
              <div>
                <h2><FaCheckCircle className="it-icons"/> Inclusions</h2>
                {inclusionsItems.length > 0 ? (
                  <ul className="itinerary-list">
                    {inclusionsItems.map((item, index) => (
                      <li key={index}>
                        <FaCheckCircle style={{ color: '#28a745' }} className="list-icons" /> {item}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No inclusions available.</p>
                )}
              </div>
            )}
            {activeTab === 'exclusions' && (
              <div>
                <h2><FaTimesCircle  className="it-icons"/> Exclusions</h2>
                {exclusionsItems.length > 0 ? (
                  <ul className="itinerary-list">
                    {exclusionsItems.map((item, index) => (
                      <li key={index}>
                        <FaTimesCircle className="list-icons" style={{ color: 'red' }} /> {item}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No exclusions available.</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TourPackageDetail;
