import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { SyncLoader } from 'react-spinners';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ContactUsComponent.css';
import LeaveReviewComponent from './LeaveReviewComponent';

const ContactUsComponent = () => {
  const initialValues = {
    name: '',
    email: '',
    phoneNumber: '',
    message: ''
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    message: Yup.string()
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await axios.post('https://mo-tours-backend.onrender.com/contact-us', values);
      toast.success('Thank you for reaching out! We will contact you shortly.');
      resetForm();
    } catch (err) {
      toast.error('Submission Failed! Please try again later.');
      // console.error(err.response ? err.response.data : err.message);
    }
    setSubmitting(false);
  };

  return (
    <div className="contact-us-leave-review-container">
      <Tabs>
        <TabList>
          <Tab>Contact Us</Tab>
          <Tab>Leave a Review</Tab>
        </TabList>

        <TabPanel>
      <div className="contact-us-container">
        <div className="section-header">
          <div className="spacing-container">
            <div className="line-wrapper">
              <p>Contact Us</p>
              <div className="elegant-line"></div>
            </div>
          </div>
        </div>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form className="contact-form">
              <div className="form-group">
                <Field type="text" name="name" placeholder="Name*" />
                <ErrorMessage name="name" component="div" className="error" />
              </div>
              <div className="form-group">
                <Field type="email" name="email" placeholder="Email*" />
                <ErrorMessage name="email" component="div" className="error" />
              </div>
              <div className="form-group">
                <Field type="tel" name="phoneNumber" placeholder="Phone Number*" />
                <ErrorMessage name="phoneNumber" component="div" className="error" />
              </div>
              <div className="form-group">
                <Field as="textarea" name="message" placeholder="Message" />
                <ErrorMessage name="message" component="div" className="error" />
              </div>
              <button type="submit" className="contact-component-btn" disabled={isSubmitting}>{isSubmitting ? <SyncLoader size={8} color="#ffffff" /> : 'Submit'}</button>
            </Form>
          )}
        </Formik>
        <ToastContainer />
      </div>
      </TabPanel>

      <TabPanel>
      
        <div className="section-header">
          <div className="spacing-container">
            <div className="line-wrapper">
              <p>Leave a Review</p>
              <div className="elegant-line"></div>
            </div>
          </div>
        </div>
        <LeaveReviewComponent />
      
      </TabPanel>

      </Tabs>
    </div>
  );
};

export default ContactUsComponent;
