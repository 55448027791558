import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import HeroSection from '../Components/HeroSection';
import './Places.css';
import Breadcrumb from '../Components/Breadcrumb';
import PuffLoader from 'react-spinners/PuffLoader';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';

const Places = () => {
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const elementsRef = useRef([]);

  useEffect(() => {
    const fetchPlaces = async () => {
      try {
        const response = await axios.get('https://mo-tours-backend.onrender.com/places');
        setPlaces(response.data.slice(0, 10)); // Limit to 10 places
        setLoading(false);
      } catch (err) {
        toast.error('Error fetching places!');
        setLoading(false);
      }
    };

    fetchPlaces();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const element = entry.target;
          if (element.classList.contains('left')) {
            element.querySelector('.place-image').classList.add('slide-in-left');
            element.querySelector('.place-description').classList.add('slide-in-right');
          } else if (element.classList.contains('right')) {
            element.querySelector('.place-image').classList.add('slide-in-right');
            element.querySelector('.place-description').classList.add('slide-in-left');
          }
          observer.unobserve(element);
        }
      });
    }, { threshold: 0.1 });

    elementsRef.current.forEach(element => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [places]);

    const paths = [
      { name: 'Home', link: '/' },
      { name: 'Places', link: '/places' }
    ];

    if (loading) {
      return (
        <div className="loader-container">
          <PuffLoader color="#044fa0" size={150} />
        </div>
      );
    }

  return (
    <>
      <ToastContainer />

      <HeroSection 
        title="Explore Our Beautiful Places" 
        subtitle="Discover the most amazing places in Rwanda" 
        imageUrl="/images/cover2.jpg" 
        isHomepage={false} 
      />
      <Breadcrumb paths={paths} />
      <div className="places-page">
        <div className="places-container">
          {places.map((place, index) => (
            <div 
              key={place._id} 
              className={`place-item ${index % 2 === 0 ? 'left' : 'right'}`} 
              ref={el => elementsRef.current[index] = el}
            >
              <div className="place-image">
                <img src={place.image} alt={place.name} />
                <div className="place-name">
                  <Link to="/tour-packages" className="place-link">{place.name}</Link>
                </div>
              </div>
              <div className="place-description">
              <h2 className="place-title">
                <Link to="/tour-packages" className="place-tittle-link">{place.name}</Link>
                </h2>
                <p>{place.description}</p>
              </div>
            </div>
          ))}
          <div className="button-container">
                  <Link to="/tour-packages" className="goto-tourpackages-btn">Go to Tour Packages</Link>
                </div>
        </div>
      </div>
    </>
  );
};

export default Places;
