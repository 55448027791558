import React from 'react';
import './CoverPhoto.css';

const CoverPhoto = () => {
  return (
    <div className="cover-photo-container" >
 <img
    src="/images/cover1.jpg"
    alt="Cover"
    className="cover-photo"
  />
      <div className="cover-photo-text">
        <h1>Explore Rwanda with Mo Tours - Your Gateway to the Land of a Thousand Hills!</h1>
        <p>Discover the beauty, history, and culture of Rwanda with Mo Tours. From the bustling streets of Kigali to the serene shores of Lake Kivu, experience the heart of Africa in a way you've never imagined. Let us be your guide to unforgettable adventures in Rwanda.</p>
      </div>
    </div>
  );
};

export default CoverPhoto;
