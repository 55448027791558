import React from 'react';
import { Link } from 'react-router-dom';
import './TopDestinations.css';

const destinations = [
  {
    name: 'Volcanoes National Park',
    image: '/images/mountainGorillas.jpg',
  },
  {
    name: "Akagera Game Lodge",
    image: '/images/akageraGameLodge.jpg',
  },
  {
    name: 'Akagera National Park',
    image: '/images/akageraNP.jpg',
  },
  {
    name: 'Nyungwe National park',
    image: '/images/nyungweNP.jpg',
  },
  {
    name: 'Kivu Belt Region',
    image: '/images/kivuBeltregion1.jpg',
  },
];

const TopDestinations = () => {
    return (
      <div className="top-destinations-section">
        <div className="spacing-container">
          <div className="line-wrapper">
            <p>Top Destinations</p>
            <div className="elegant-line"></div>
          </div>
        </div>
        <div className="destinations-container">
          {destinations.map((destination, index) => (
            <div key={index} className={`destination destination-${index}`}>
              <img src={destination.image} alt={destination.name} className="destination-image" />
              <a href={destination.link} className="destination-name">{destination.name}</a>
            </div>
          ))}
        </div>
        <div className="button-container">
        <button className="more-destinations-btn"> <Link to="/places">See More Destinations</Link></button>
          </div>
      </div>
    );
  };
  
  export default TopDestinations;
