
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import './HeroSection.css';
import { useNavigate } from 'react-router-dom';
import NavBar from './NavBar';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { Container, Button, Box } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SyncLoader } from 'react-spinners';

const slides = [
  {
    url: '/images/slide1.jpg',
    text: 'Explore the Gorillas',
    description: 'Visit Volcanoes national Park today'
  },
  {
    url: '/images/slide2.jpg',
    text: 'Immerse Yourself in Rwandas amazing nature',
    description: 'Experience the serene allure of Rwandas natural beauty, with tranquil moments in the heart of the forest.'
  },
  {
    url: '/images/slide3.jpg',
    text: 'Kigalis Iconic Night Lights',
    description: 'Marvel at the stunning glow of the Kigali Convention Center.'
  },
  {
    url: '/images/slide4.jpg',
    text: 'Never ending adventures',
    description: 'Embrace the simple joys of lake life and Rwandas natural beauty.'
  },
  {
    url: '/images/slide5.jpg',
    text: 'Best city tours',
    description: 'Experience the vibrant capital of Rwanda Kigali'
  },
  {
    url: '/images/slide6.jpg',
    text: 'Discover Vibrant Outdoor Adventures',
    description: 'Experience the best of Rwandas annual events while soaking in breathtaking views'
  }
];

const HeroSection = ({ title, subtitle, imageUrl, isHomepage, customHeight }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();

  const goToPrevious = useCallback(() => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
  }, []);

  const goToNext = useCallback(() => {
    setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
  }, []);

  useEffect(() => {
    if (isHomepage) {
      const slideInterval = setInterval(goToNext, 5000); // Change slide every 5 seconds
      return () => clearInterval(slideInterval); 
    }
  }, [goToNext, isHomepage]);

  const handleBookNowClick = () => {
    navigate('/book-now');
  };

  const [packages, setPackages] = useState([]);
  const [loadingPackages, setLoadingPackages] = useState(true);
  const [errorPackages, setErrorPackages] = useState(false);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get('https://mo-tours-backend.onrender.com/package-tours');
        const packageOptions = response.data.map(pkg => ({
          value: pkg.name,
          label: pkg.name
        }));
        setPackages(packageOptions);
        setLoadingPackages(false);
      } catch (error) {
        toast.error('Error fetching tour packages:', error);
        setErrorPackages(true);
        setLoadingPackages(false);
      }
    };

    fetchPackages();
  }, []);

  const initialValues = {
    email: '',
    departureDate: '',
    packageTour: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    departureDate: Yup.date().required('Departure Date is required'),
    packageTour: Yup.string(),
  });




  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await axios.post('https://mo-tours-backend.onrender.com/request-quote', values);
      toast.success('Quotation request submitted! We will contact you soon.');
      resetForm();
    } catch (err) {
      toast.error('Submission Failed: There was an error submitting your request. Please try again later.');
      console.error(err.response ? err.response.data : err.message);
    }
    setSubmitting(false);
  };



  return (
    <div className="hero-container" style={{ height: isHomepage ? '100vh' : customHeight || 'auto' }}>
      <NavBar showFullNav={true} />
      {isHomepage ? (
        <div className="slideshow">
          {slides.map((slide, index) => (
            <div key={index} className={`slide ${index === currentSlide ? 'active' : ''}`}>
              <img src={slide.url} alt={`Slide ${index + 1}`} />
              <div className={`hero-content ${index === currentSlide ? 'active' : ''}`}>
                <h1 className="hero-title">{slide.text}</h1>
                <p className="hero-description">{slide.description}</p>
                <button
                  key={`hero-book-now-btn-${index}`}
                  className="hero-book-now-btn"
                  onClick={handleBookNowClick}
                >
                  Book Now
                </button>
              </div>
            </div>
          ))}
          <button className="prev" onClick={goToPrevious} aria-label="Go to previous"><FaChevronLeft /></button>
          <button className="next" onClick={goToNext} aria-label="Go to next"><FaChevronRight /></button>
          
          
          <div className="hero-form-container">
          <Container className="hero-form-container" maxWidth="md" style={{ marginTop: '2rem', backgroundColor: 'rgba(255, 255, 255)', padding: '2rem', borderRadius: '8px' }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form className="form-fields">
                <Box className="form-box">
                  <div className="form-group">
                    <label className="labels">Email</label>
                    <Field type="email" name="email" className="form-field" />
                    <ErrorMessage name="email" component="div" className="error" />
                  </div>
                  <div className="form-group">
                    <label className="labels">Departure Date</label>
                    <Field type="date" name="departureDate" className="form-field" />
                    <ErrorMessage name="departureDate" component="div" className="error" />
                  </div>
                  <div className="form-group">
                    <label className="labels">Packages (Optional)</label>
                    <Select
                      options={packages}
                      name="packageTour"
                      value={packages.find(option => option.value === values.packageTour) || null}
                      onChange={(option) => setFieldValue('packageTour', option.value)}
                      placeholder="Select a package"
                      noOptionsMessage={() => 
                        loadingPackages ? <SyncLoader size={8} color="#044fa0" /> : 
                        errorPackages ? "Failed to load packages" : 
                        "No packages available"
                      }
                      className="form-field"
                    />
                    <ErrorMessage name="packageTour" component="div" className="error" />
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    className="quote-submit-btn"
                  >
                    {isSubmitting ? <SyncLoader size={8} color="#ffffff" /> : 'Request Quotation'}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
          <ToastContainer />
        </Container>
        </div>

        </div>
      ) : (
        <div className="single-image" style={{ height: customHeight || '70vh' }}>
          <img src={imageUrl} alt={title} />
          <div className="hero-content">
            <h1 className="hero-title">{title}</h1>
            <p className="hero-description">{subtitle}</p>
            <button
                  className="hero-book-now-btn"
                  onClick={handleBookNowClick}
                >
                  Book Now
                </button>
          </div>
        </div>
      )}


    
    </div>
  );
};

export default HeroSection;

