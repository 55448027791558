import React from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import NavBar from '../Components/NavBar';
import {useNavigate}  from 'react-router-dom';
import { SyncLoader } from 'react-spinners';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaArrowLeft } from 'react-icons/fa';
import './CustomTour.css';
import Breadcrumb from '../Components/Breadcrumb';

const CustomTour = () => {
  const navigate = useNavigate();

  const initialValues = {
    name: '',
    email: '',
    phoneNumber: '',
    departureDate: '',
    duration: '',
    numberOfTravellers: '',
    message: ''
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phoneNumber: Yup.string().required('Phone Number is required'),
    departureDate: Yup.date().required('Departure Date is required'),
    duration: Yup.number().required('Duration is required'),
    numberOfTravellers: Yup.number().required('Number of Travellers is required'),
    message: Yup.string().required('Message is required')
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await axios.post('https://mo-tours-backend.onrender.com/custom-tours', values);
      toast.success('Thank you for requesting a custom tour! We will contact you soon.');
      resetForm();
    } catch (error) {
      toast.error('Submission Failed: There was an error processing your request. Please try again later.');
      console.error(error.response ? error.response.data : error.message);
    }
    setSubmitting(false);
  };

  const paths = [
    { name: 'Home', link: '/' },
    { name: 'Custom Tour', link: '/custom-tour' }
  ];

  return (
    <>
      <NavBar showFullNav={false} />
      <Breadcrumb paths={paths} isFormPage={true} />
      <div className="custom-tour-container">
      <button className="back-button" onClick={() => navigate(-1)}><FaArrowLeft /></button>
        <h2>Request a Custom Tour</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="custom-tour-form">
              <div className="form-group">
                <label>Name</label>
                <Field type="text" name="name" />
                <ErrorMessage name="name" component="div" className="error" />
              </div>
              <div className="form-group">
                <label>Email</label>
                <Field type="email" name="email" />
                <ErrorMessage name="email" component="div" className="error" />
              </div>
              <div className="form-group">
                <label>Phone Number</label>
                <Field type="text" name="phoneNumber" />
                <ErrorMessage name="phoneNumber" component="div" className="error" />
              </div>
              <div className="form-group">
                <label>Departure Date</label>
                <Field type="date" name="departureDate" />
                <ErrorMessage name="departureDate" component="div" className="error" />
              </div>
              <div className="form-group">
                <label>Duration (days)</label>
                <Field type="number" name="duration" />
                <ErrorMessage name="duration" component="div" className="error" />
              </div>
              <div className="form-group">
                <label>Number of Travellers</label>
                <Field type="number" name="numberOfTravellers" />
                <ErrorMessage name="numberOfTravellers" component="div" className="error" />
              </div>
              <div className="form-group">
                <label>Message</label>
                <Field as="textarea" name="message" />
                <ErrorMessage name="message" component="div" className="error" />
              </div>
              <button type="submit" disabled={isSubmitting} className="custom-submit-btn">{isSubmitting ? <SyncLoader size={8} color="#ffffff" /> : 'Submit Request'}</button>
            </Form>
          )}
        </Formik>
        <ToastContainer />
      </div>
    </>
  );
};

export default CustomTour;
