import React from 'react';
import HeroSection from '../Components/HeroSection';
import TourPackagesComponent from '../Components/TourPackagesComponent';
import WhyChooseUsComponent from '../Components/WhyChooseUsComponent';
import TopDestinations from '../Components/TopDestinations';
import WhatOtherClientsSay from '../Components/WhatOtherClientsSay';
import CoverPhoto from '../Components/CoverPhoto';
import ContactUsComponent from '../Components/ContactUsComponent';
import './Homepage.css';

const Homepage = () => {
  return (
    <>
      <HeroSection isHomepage={true} />
      <TourPackagesComponent />
      <WhyChooseUsComponent />
      <TopDestinations />
      <WhatOtherClientsSay />
      <CoverPhoto />
      <ContactUsComponent />
    </>
  );
};

export default Homepage;
