import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Select from 'react-select';
import { SyncLoader } from 'react-spinners';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaArrowLeft } from 'react-icons/fa';
import NavBar from '../Components/NavBar';
import './LeaveReview.css';
import Breadcrumb from '../Components/Breadcrumb';

const LeaveReviewPage = () => {
  const navigate = useNavigate();

  const initialValues = {
    name: '',
    rating: null,
    comment: ''
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    rating: Yup.object()
      .shape({
        value: Yup.number().required('Rating is required').min(1).max(5),
        label: Yup.string().required()
      })
      .nullable()
      .required('Rating is required'),
    comment: Yup.string().required('Comment is required')
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const reviewDetails = {
      name: values.name,
      rating: values.rating.value,
      comment: values.comment
    };

    try {
      await axios.post('https://mo-tours-backend.onrender.com/reviews', reviewDetails);
      toast.success('Thank you for your review! We appreciate your feedback.');
      resetForm();
    } catch (error) {
      toast.error('Submission Failed: There was an error submitting your review. Please try again later.');
      // console.error('There was an error submitting your review:', error.response ? error.response.data : error.message);
    }
    setSubmitting(false);
  };

  const ratingOptions = [
    { value: 1, label: '1 Star' },
    { value: 2, label: '2 Stars' },
    { value: 3, label: '3 Stars' },
    { value: 4, label: '4 Stars' },
    { value: 5, label: '5 Stars' }
  ];

  const paths = [
    { name: 'Home', link: '/' },
    { name: 'Leave a review', link: '/leave-review' }
  ];

  return (
    <>
      <NavBar showFullNav={false} />
      <Breadcrumb paths={paths} isFormPage={true} />
      <div className="leave-review-page-container">
        <button className="back-button" onClick={() => navigate(-1)}>
          <FaArrowLeft />
        </button>
        <h2>Leave a Review</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form className="leave-review-form">
              <div className="form-group">
                <label>Rating</label>
                <Select
                  options={ratingOptions}
                  name="rating"
                  value={ratingOptions.find(option => option.value === values.rating?.value) || null}
                  onChange={(option) => setFieldValue('rating', option)}
                  placeholder="Select a rating"
                />
                <ErrorMessage name="rating" component="div" className="error" />
              </div>
              <div className="form-group">
                <label>Name</label>
                <Field type="text" name="name" />
                <ErrorMessage name="name" component="div" className="error" />
              </div>
              <div className="form-group">
                <label>Comment</label>
                <Field as="textarea" name="comment" placeholder="Your comments" />
                <ErrorMessage name="comment" component="div" className="error" />
              </div>
              <button type="submit" disabled={isSubmitting} className="leave-review-submit-btn">
                {isSubmitting ? <SyncLoader size={8} color="#ffffff" /> : 'Submit Review'}
              </button>
            </Form>
          )}
        </Formik>
        <ToastContainer />
      </div>
    </>
  );
};

export default LeaveReviewPage;
