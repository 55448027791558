import React, { useEffect, useState } from 'react';
import { FaFacebookF, FaWhatsapp, FaInstagram, FaArrowUp, FaHome, FaBookOpen, FaPhoneAlt, FaMapMarkerAlt, FaPencilAlt, FaUserCheck, FaQuestionCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const halfwayPoint = document.documentElement.scrollHeight / 4;
    if (scrollPosition >= halfwayPoint) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Run the scroll handler once to set the initial state
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
  }, [showScrollButton]);

  return (
    <div className="footer-section">
      <div className="footer-top"></div>
      <div className="footer-content">
        <div className="footer-div">
          <h4>Explore</h4>
          <ul>
            <li><FaHome className="icon" /> <Link to="/">Home</Link></li>  
            <li><FaMapMarkerAlt className="icon" /> <Link to="/places">Places</Link></li>
            <li><FaBookOpen className="icon" /> <Link to="/tour-packages">Tour Packages</Link></li>
            <li><FaPencilAlt className="icon" /> <Link to="/custom-tours">Custom Tour</Link></li>
          </ul>
          <button className="book-now-btn">Book Now</button>
        </div>
        <div className="footer-div">
          <h4>More Info</h4>
          <ul>
            <li><FaPhoneAlt className="icon" /> <Link to="/contact-us">Contact Us</Link></li>
            <li><FaQuestionCircle className="icon" /> <Link to="/faq">FAQ</Link></li>
            <li><FaUserCheck className="icon" /><Link to="/my-booking" > My Booking</Link></li>
            <li><FaUserCheck className="icon" /> <Link to="/leave-review">Leave a review</Link></li>
          </ul>
        </div>
        <div className="footer-div">
          <h4>About Us</h4>
          <p>We provide the best travel experiences and custom tours for our customers. Our mission is to make your travel dreams come true with exceptional service and unforgettable journeys.</p>
        </div>
      </div>
      <div className="footer-social-icons">
        <FaWhatsapp />
        <FaInstagram />
        <FaFacebookF />
      </div>
      <div className="footer-line"></div>
      <div className="footer-bottom">
        <p>© 2024 MO Tours. All rights reserved</p>
        <div className="payment-methods">
          <img src="/cash.png" alt="Cash" />
          <img src="/visa.png" alt="Visa" />
          <img src="mastercard.png" alt="Mastercard" />
          <img src="/momo.png" alt="Momo" />
        </div>
      </div>
      <div className={`back-to-top ${showScrollButton ? 'visible' : ''}`} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
        <FaArrowUp />
      </div>
    </div>
  );
};

export default Footer;
