import React from 'react';
import './WhatOtherClientsSay.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


const reviews = [
  {
    stars: 5,
    review: "Gorilla trekking in Volcanoes National Park was the highlight of my trip to Rwanda! Seeing these majestic animals up close in their natural habitat is a once-in-a-lifetime experience. Mo Tours made everything seamless, from transportation to guiding us.",
    name: "Lindsey M",
  },
  {
    stars: 5,
    review: "Nyungwe National Park is a paradise for nature lovers. The canopy walk was thrilling and offered breathtaking views of the rainforest. Mo Tours made everything stress-free. An unforgettable adventure!",
    name: "Maritza R",
  },
  {
    stars: 5,
    review: "The custom tour we booked with Mo Tours was exceptional! They tailored the itinerary to fit everything we wanted to see in Rwanda. From the culture to the landscapes, we experienced it all. The service was top-notch, and the guide was friendly and informative.",
    name: "Travis S",
  },
  {
    stars: 5,
    review: "The visit to the King's Palace Museum in Nyanza was like stepping back in time. The Inyambo cows and royal artifacts were fascinating! A great way to learn about Rwanda’s monarchy and traditions. Highly recommend this tour for history lovers.",
    name: "Shyaka P",
  },
  {
    stars: 5,
    review: "The Kigali City Tour was an eye-opening experience! From the Genocide Memorial to the bustling Kimironko Market, every stop was filled with rich history and culture. Our guide was amazing and made the experience unforgettable.",
    name: "Michael L",
  },
  {
    stars: 5,
    review: "Exceptional service and unforgettable experiences. Will definitely book again!",
    name: "Amir R",
  },
  {
    stars: 5,
    review: "The Ethnography Museum in Huye was an enriching experience. I learned so much about Rwanda's history and culture. Our guide was knowledgeable and engaging, making the tour truly enjoyable. A must-visit for anyone interested in Rwanda’s heritage.",
    name: "Anta M",
  },
  {
    stars: 5,
    review: "Akagera National Park is a hidden gem! We saw elephants, lions, and even rhinos on the safari. The tour was well-organized and our guide was very knowledgeable. A perfect way to experience Rwanda’s incredible wildlife!",
    name: "Anita G",
  }
];

const WhatOtherClientsSay = () => {
  return (
    <div className="what-other-clients-say-section">
      <div className="flex-container">
      <div className="spacing-Container">
        <div className="line-Wrapper">
          <p>What Other Clients Say</p>
          <div className="elegant-Line"></div>
        </div>
        <p className="p2">These are reviews from both our website and Trust Pilot. You can view or leave a review on Trust Pilot by clicking below.</p>
      </div>
     
      <div className="swiper-container">
        <Swiper
          spaceBetween={10}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          navigation={{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
          }}
          pagination={{
            clickable: true,
            
            
          }}

          
          modules={[Navigation, Pagination]}
        >
          {reviews.map((review, index) => (
            <SwiperSlide key={index}>
              <div className="review">
                <div className="stars">
                  {Array.from({ length: review.stars }, (_, i) => (
                    <span key={i}>&#9733;</span>
                  ))}
                </div>
                <div className="review-text">{review.review}</div>
                <div className="reviewer-name">{review.name}</div>
              </div>
            </SwiperSlide>
          ))}
        
        
          
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
        </Swiper>
      </div>
       
    
     </div> 
     <div class="button-wrapper"><a href="https://www.trustpilot.com" target="_blank" rel="noopener noreferrer" className="other-reviews-btn">See Other Trust Pilot Reviews</a></div>
    </div>
   
  );
};

export default WhatOtherClientsSay;