import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import HeroSection from '../Components/HeroSection';
import './TourPackagesPage.css';
import Breadcrumb from '../Components/Breadcrumb';
import PuffLoader from 'react-spinners/PuffLoader';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import { FaMapMarkerAlt} from 'react-icons/fa'

const TourPackagesPage = () => {
  const [tourPackages, setTourPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTourPackages = async () => {
      try {
        const response = await axios.get('https://mo-tours-backend.onrender.com/package-tours');
        const packagesWithDollarSign = response.data.map(pkg => ({
          ...pkg,
          price: `$${pkg.price}`
        }));
        setTourPackages(packagesWithDollarSign);
        setLoading(false);
      } catch (err) {
        toast.error('Error fetching places!');
        setLoading(false);
      }
    };

    fetchTourPackages();
  }, []);

  const paths = [
    { name: 'Home', link: '/' },
    { name: 'Tour Packages', link: '/tour-packages' }
  ];

  if (loading) {
    return (
      <div className="loader-container">
        <PuffLoader color="#044fa0" size={150} />
      </div>
    );
  }

  const handleBookNowClick = (event, pkgName) => {
    event.preventDefault();
    navigate('/book-now', { state: { packageTour: pkgName } });
  };

  return (
    <>
      <ToastContainer />
      
      <HeroSection 
        title="Explore Our Tour Packages" 
        subtitle="Discover the best tours in Rwanda" 
        imageUrl="/images/cover2.jpg" 
        isHomepage={false} 
      />
      <Breadcrumb paths={paths} />
      <div className="tour-packages-page-container">
        {tourPackages.map((pkg, index) => (
            <div className="tour-packages-page-item">
              <Link to={`/tour-packages/${pkg._id}`} key={index} className="tour-packages-page-link">
              <img src={pkg.image} alt={pkg.name} className="tour-packages-page-img" />
              <div className="tour-packages-page-info">
                <h3 ><FaMapMarkerAlt size={16} />{pkg.name}</h3>
                <p>{pkg.description}</p>
              </div>
              <div className="tour-packages-page-price-button">
                <div className="price">
                  <div>
                  <span className="from">from </span>
                  <span className="amount">{pkg.price}</span>
                  </div>
                  <div className="details">Click for more tour package info</div>
                </div>
                <div className="button-container">
                <button
                  onClick={(event) => handleBookNowClick(event, pkg.name)}
                  className="book-now-btn"
                >
                  Book Now
                </button>
              </div>
              </div>
              </Link>
            </div>
        ))}
        <div className="tour-packages-page-more-info-container">
          <div className="tour-packages-page-more-info">
            <div className="description">
              Want to customize your tour? Contact us for a personalized experience!
            </div>
            <div className="button-container">
              <Link to="/contact-us" className="contact-btn">Contact Us</Link>
              <Link to="/custom-tour" className="view-btn">Customize Now</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TourPackagesPage;
