import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FaShoppingCart, FaUserCheck, FaEnvelope, FaPhone, FaInfoCircle, FaFacebookF, FaWhatsapp, FaInstagram } from 'react-icons/fa';
import './NavBar.css';

const NavBar = ({showFullNav}) => {
  
  return (
    <div className="navbar-container">
      <div className="top-navbar">
          <Link to="/" className="logo">
          <img src="/logo.jpg" alt="MO TOURS" />
          <span className="logo-text">MO TOURS</span>
          </Link>
        <div className="contact-info">
          <Link to="/book-now" className="book-now-link"><FaShoppingCart className="icon" />Book Now</Link>
          <Link to="/my-booking" className="my-booking-link"><FaUserCheck className="icon" /> My Booking</Link>
          <Link to="/contact-us" className="contact-us-link"><FaInfoCircle className="icon" />Contact Us</Link>
            <span><FaEnvelope className="icon" />Email Us: motourservices@gmail.com</span>
            <span><FaPhone className="icon" />Call Us: +250783147426</span>
            <span className="nav-social-icons">
            <span><FaWhatsapp /></span>
            <span><FaInstagram /></span>
            <span><FaFacebookF /></span>
          </span>
          
        </div>
      </div>
      {showFullNav &&(
      <div className="main-navbar">
      <ul className="nav-links">
          <li>
            <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>Home </NavLink>
          </li>
          <li>
            <NavLink  to="/places"  className={({ isActive }) => (isActive ? 'active' : '')}> Places</NavLink>
          </li>
          <li>
            <NavLink  to="/tour-packages" className={({ isActive }) => (isActive ? 'active' : '')}>Tour Packages </NavLink>
          </li>
          <li>
            <NavLink to="/custom-tour" className={({ isActive }) => (isActive ? 'active' : '')} >Custom Tour</NavLink>
          </li>
        </ul>
      </div>
      )}
    </div>
  );
};

export default NavBar;
